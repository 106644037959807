import React, { useState } from "react";
import {
  Button,
  InputAdornment,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

import CustomCard from "./CustomCard";
import CustomLoader from "./CustomLoader";
import { CustomToast } from "./CustomToast";

import { cancelOrder, editOrder } from "../services/orders.service";

import { numbersOnly } from "../validator/numberValidator";
import CustomTooltip from "./CustomTooltip";
import { useAuth } from "../auth/AuthContext";

const OrdersTableSection = ({ isOrdersLoading, orders }) => {
  const { logout } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openEditOrderModal, setOpenEditOrderModal] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      symbol: null,
      entry_exit: null,
      order_id: null,
      order_side: null,
      order_type: null,
      order_time: null,
      order_quantity: null,
      order_price: null,
      order_stop_price: null,
    },
  });

  let symbol = watch("symbol");
  let entry_exit = watch("entry_exit");
  let order_side = watch("order_side");
  let order_type = watch("order_type");
  let order_time = watch("order_time");
  let order_quantity = watch("order_quantity");

  const onSubmit = (data) => {
    setFormSubmitted(true);
    let order_id = data["order_id"];
    if (entry_exit === "entry") {
      data.order_quantity = parseFloat(data.order_quantity);
    } else {
      delete data.order_quantity;
    }
    if (data.order_type !== "limit") {
      delete data.order_price;
    } else {
      data.order_price = parseFloat(data.order_price);
    }
    if (entry_exit === "sl") {
      data.order_stop_price = parseFloat(data.order_stop_price);
      if (isNaN(data.order_stop_price)) {
        delete data.order_stop_price;
      }
    } else {
      delete data.order_stop_price;
    }
    delete data.entry_exit;
    delete data.order_type;
    delete data.order_id;
    delete data.order_side;
    delete data.order_time;
    delete data.is_stop_loss;

    editOrder(order_id, data)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setFormSubmitted(false);
        setOpenEditOrderModal(false);
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
        setFormSubmitted(false);
      });
  };

  const editOrderById = (orderData) => {
    reset();
    setValue("symbol", orderData["symbol"]);
    setValue("entry_exit", orderData["order_type_in_trade_manager"]);
    setValue("order_id", orderData["id"]);
    setValue("order_side", orderData["side"]);
    setValue("order_type", orderData["type"]);
    setValue("order_time", orderData["datetime"]);
    setValue("order_quantity", orderData["amount"]);
    if (orderData["type"] === "limit") {
      setValue("order_price", orderData["price"]);
    }
    if (orderData["order_type_in_trade_manager"] === "sl") {
      setValue("order_stop_price", orderData["stop_loss_price"]);
    }
    setOpenEditOrderModal(true);
  };

  const cancelOrderById = (orderId) => {
    cancelOrder(orderId)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
      });
  };
  return (
    <CustomCard cardTitle="Orders Table">
      <TableContainer style={{ maxHeight: "60vh" }}>
        <Table>
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Symbol</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Order Time</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Side</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Order Type</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Order Price</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Qty</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Order Status</TableCell>
              <TableCell sx={{ width: "25%", fontWeight: 600 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: "auto" }}>
            {isOrdersLoading ? (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={8}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ) : orders.length > 0 ? (
              orders
                .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                .map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.symbol}</TableCell>
                    <TableCell>{order.datetime}</TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {order.side}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {order.type}
                    </TableCell>
                    <TableCell>{order.price}</TableCell>
                    <TableCell>
                      {/* In coinbase canceled order are not shown but in one example of ROSE/USD
                      found that partially filled and later canceled will be present in orders 
                      so showing filled qty as qty for closed & canceled and amount as qty for open */}
                      {order.status === "open" ? order.amount : order.filled}
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {order.status}
                    </TableCell>
                    <TableCell>
                      <Stack direction={"row"} gap={"10px"}>
                        {["open", "rejected"].includes(order.status) && (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              fontWeight: 600,
                            }}
                            onClick={() => editOrderById(order)}
                          >
                            Edit
                          </Button>
                        )}
                        {["open"].includes(order.status) && (
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              fontWeight: 600,
                            }}
                            onClick={() => cancelOrderById(order.id)}
                          >
                            Cancel
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center", letterSpacing: "1.5px" }}
                  colSpan={8}
                >
                  No orders found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={openEditOrderModal}
        onClose={() => {
          reset();
          setOpenEditOrderModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
          style={{
            minWidth: "25vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "background.paper",
            boxShadow: 24,
          }}
        >
          <CustomCard cardTitle="Edit Order" rightComponent={<></>}>
            <Stack direction="column" gap={"20px"}>
              <Stack direction="row" gap={"20px"}>
                <Typography
                  width="6rem"
                  variant="subtitle2"
                  component="div"
                  style={{ fontWeight: "600" }}
                >
                  Symbol:
                </Typography>
                <Typography variant="subtitle2" component="div">
                  {symbol}
                </Typography>
              </Stack>
              {entry_exit && (
                <Stack direction="row" gap={"20px"}>
                  <Typography
                    width="6rem"
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Entry/Exit:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textTransform="uppercase"
                  >
                    {entry_exit === "entry" ? entry_exit : "exit " + entry_exit}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" gap={"20px"}>
                <Typography
                  width="6rem"
                  variant="subtitle2"
                  component="div"
                  style={{ fontWeight: "600" }}
                >
                  Side:
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  textTransform="capitalize"
                >
                  {order_side}
                </Typography>
              </Stack>
              <Stack direction="row" gap={"20px"}>
                <Typography
                  width="6rem"
                  variant="subtitle2"
                  component="div"
                  style={{ fontWeight: "600" }}
                >
                  Type:
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  textTransform="capitalize"
                >
                  {order_type}
                </Typography>
              </Stack>
              <Stack direction="row" gap={"20px"}>
                <Typography
                  width="6rem"
                  variant="subtitle2"
                  component="div"
                  style={{ fontWeight: "600" }}
                >
                  Time:
                </Typography>
                <Typography variant="subtitle2" component="div">
                  {order_time}
                </Typography>
              </Stack>
              {entry_exit === "entry" ? (
                <Stack>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Order Quantity
                  </Typography>
                  <Stack direction={"row"} alignItems="center" gap={"20px"}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Order Quantity"
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {symbol?.split("/")[0]}
                          </InputAdornment>
                        ),
                        style: { fontSize: 15 },
                      }}
                      style={{ flex: 3 }}
                      {...register("order_quantity")}
                    />
                  </Stack>
                  {errors.order_quantity?.message && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {errors.order_quantity?.message}
                    </span>
                  )}
                </Stack>
              ) : (
                <Stack direction="row" gap={"20px"}>
                  <Typography
                    width="6rem"
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Order Quantity:
                  </Typography>
                  <Typography variant="subtitle2" component="div">
                    {order_quantity}
                  </Typography>
                </Stack>
              )}
              {order_type === "limit" && (
                <Stack>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Order Price
                  </Typography>
                  <Stack direction={"row"} alignItems="center" gap={"20px"}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Order Price"
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {symbol?.split("/")[1]}
                          </InputAdornment>
                        ),
                        style: { fontSize: 15 },
                      }}
                      style={{ flex: 3 }}
                      {...register("order_price")}
                    />
                  </Stack>
                  {errors.order_price?.message && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {errors.order_price?.message}
                    </span>
                  )}
                </Stack>
              )}
              {entry_exit === "sl" && (
                <Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    paddingBottom={"5px"}
                  >
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Stop Loss
                      </Typography>
                      <CustomTooltip text="Stop Loss tooltip" />
                    </Stack>
                  </Stack>
                  <Stack>
                    <TextField
                      name="order_stop_price"
                      type="text"
                      placeholder="Stop Price"
                      variant="outlined"
                      size="small"
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ gap: "10px" }}
                          >
                            {symbol?.split("/")[1]}
                          </InputAdornment>
                        ),
                      }}
                      {...register("order_stop_price")}
                    />
                    {errors.order_stop_price?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.order_stop_price?.message}
                      </span>
                    )}
                  </Stack>
                </Stack>
              )}
              <Stack direction="row" gap={"5px"}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formSubmitted}
                  style={{ cursor: formSubmitted ? "not-allowed" : "default" }}
                  sx={{
                    fontWeight: 600,
                    flex: 1,
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    fontWeight: 600,
                    flex: 1,
                  }}
                  onClick={() => {
                    reset();
                    setOpenEditOrderModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </CustomCard>
        </form>
      </Modal>
    </CustomCard>
  );
};

export default OrdersTableSection;
